$(function(){
    $('#patient-portal-form').validator();
    $('#forgot-password-btn,#header-forgot-password-btn').on('click',function(e){
        bootbox.dialog({
            title: 'Forgot Password',
            message:
            '<form action="https://ucm.prognocis.com/prognocis/PatLogin.do?operation=ForgotPwd&source=PT&invokefrom=site" id="forgot-password-form" name="form" method="POST" target="PatientLogin2" autocomplete="off">' +
            '<input type="hidden" name="mbIsLoginSuccess" value="false">' +
            '<input type="hidden" name="msPatId" value="">' +
            '<input type="hidden" name="msPwdDate" value="">' +
            '<input type="hidden" name="msClinic" value="ucm">' +
            '<input type="hidden" name="clinic" value="ucm">' +
            '<input type="hidden" name="msid" value="">' +
            '<input type="hidden" name="msOrderBy" value="">' +
            '<input type="hidden" name="msParam" value="">' +
            '<input type="hidden" name="msIsChanged" value="false">' +
            '<input type="hidden" name="msScreenTitle" value="">' +
            '<input type="hidden" name="msSearchTitle" value="">' +
            '<input type="hidden" name="msprevid" value="">' +
            '<input type="hidden" name="msIdFieldName" value="">' +
            '<input type="hidden" name="msSectionName" value="">' +
            '<input type="hidden" name="msDisplayWidth" value="">' +
            '<input type="hidden" name="msParamWhere" value="">' +
            '<input type="hidden" name="msHelp" value="">' +
            '<input type="hidden" name="msMessageType" value="">' +
            '<input type="hidden" name="msFocus" value="">'+
            '<input type="hidden" name="msOperation" value="">' +
            '<input type="hidden" name="msdisplayprevRecStatus" value="">'+
            '<input type="hidden" name="msisAdd" value="false">' +
            '<input type="hidden" name="msisUpdate" value="false">' +
            '<input type="hidden" name="msisDelete" value="false">' +
            '<input type="hidden" name="msLastModUserId" value="">' +
            '<input type="hidden" name="msLastModTimestamp" value="">' +
            '<input type="hidden" name="msMenuOpt" value="">' +
            '<input type="hidden" name="miErrRecno" value="0">' +
            '<input type="hidden" name="mbIsError" value="false">' +
            '<input type="hidden" name="msNextOperation" value="">' +
            '<input type="hidden" name="mbNoRecordFound" value="false">' +
            '<input type="hidden" name="msExpressionMessage" value="">' +
            '<input type="hidden" name="msIsGuidelinePresent" value="">' +
            '<input type="hidden" name="msMultiLocation" value="">' +
            '<input type="hidden" name="msDefaultLocation" value="">' +
            '<input type="hidden" name="msPassword" value="">' +
            '<div class="form-group">' +
            '<label for="forgotPasswordUser">User ID</label>' +
            '<input type="text" class="form-control" id="forgotPasswordUser" placeholder="User ID" name="msUserId">' +
            '</div>' +
            '</form>',
            buttons: {
                submit: {
                    label: "Submit",
                    className: "btn-success",
                    callback: function(e) {
                        var username = $('#forgotPasswordUser').val();
                        if(username === ""){
                            return false;
                        }else{
                            $('#forgot-password-form').submit();
                        }
                    }
                }
            }
        });
    });
});
