$(function(){
    $('#patient-name,#patient-consent').on('change input',function (e) {
        var patientName = $('#patient-name').val();
        var patientConsent = $('#patient-consent').is(':checked');
        var submitButton = $('#patient-consent-submit');
        submitButton.prop({disabled : patientName === '' || !patientConsent});
    });

    $('#consent-form').on('submit',function(e) {
        e.preventDefault();
        $('#consent-form-area').html('<div class="loader center-block"></div>');
        var url = $(this).attr('action');
        var formData = $(this).serializeArray();
        $.post(url,prepareConsentRequestData(formData),function(response){
            $('#consent-form-area').html(
                '<div class="alert alert-success" role="alert">'+ response.message +'</div>'
            );
        }).fail(function(response) {
            if(typeof response.message === 'undefined'){
                response.message = 'An error has occurred';
            }
            $('#contact-form-area').html(
                '<div class="alert alert-danger" role="alert">'+ response.message +'</div>'
            );
        });
    });

    function prepareConsentRequestData(formData){
        var requestData = {fields: {}};
        $.each(formData,function(i,field){
            requestData.fields[field.name] = field.value;
        });
        return {data : JSON.stringify(requestData)};
    }
});