$( document ).ready(function() {
    $('#close-call-us-now').on('click',function(){
        var width = $( window ).width();
        $("#call-us-now").animate({ 'margin-top' : width > 767 ? '-30px' : '-75px'}, "slow");
    });
    $('#open-call-us-now').on('click',function(){
        $("#call-us-now").animate({ 'margin-top' : '0' }, "slow");
    });

    $('#request-a-consult-btn').on('click',function () {
        setTimeout(function() {
            $('#header-patient-portal-form').validator();
        }, 1000);
        bootbox.dialog({
            size: 'large',
            message:
            '<p class="text-center h3">Request a Consult</p>' +
            '<p class="text-center h4">Members can request a consult 24/7 by calling</p>' +
            '<p id="orange-phone-number" class="text-center">1-844-4-VIP-DOC</p>' +
            '<p class="text-center" id="small-phone-number">(<a href="tel:+18444847362">1-844.484.7362</a>)</p>' +
            // '<p id="orange-phone-number-backup" class="text-center">or 518-268-2506</p>' +
            '<p class="text-center h4">or log in to schedule a consult</p>' +
            "    <div class=\"row\">" +
            "        <div class=\"col-sm-6 col-sm-push-3\">" +
            "            <form id=\"header-patient-portal-form\" name=\"form\" method=\"POST\" action=\"https://ucm.prognocis.com/prognocis/PatLogin.do?operation=Login&source=PT&invokefrom=site\" target=\"_blank\" autocomplete=\"off\">" +
            "                <input type=\"hidden\" name=\"mbIsLoginSuccess\" value=\"false\">" +
            "                <input type=\"hidden\" name=\"msPatId\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msPwdDate\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msClinic\" value=\"ucm\">" +
            "                <input type=\"hidden\" name=\"clinic\" value=\"ucm\">" +
            "                <input type=\"hidden\" name=\"msid\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msOrderBy\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msParam\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msIsChanged\" value=\"false\">" +
            "                <input type=\"hidden\" name=\"msScreenTitle\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msSearchTitle\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msprevid\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msIdFieldName\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msSectionName\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msDisplayWidth\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msParamWhere\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msHelp\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msMessageType\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msFocus\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msOperation\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msdisplayprevRecStatus\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msisAdd\" value=\"false\">" +
            "                <input type=\"hidden\" name=\"msisUpdate\" value=\"false\">" +
            "                <input type=\"hidden\" name=\"msisDelete\" value=\"false\">" +
            "                <input type=\"hidden\" name=\"msLastModUserId\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msLastModTimestamp\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msMenuOpt\" value=\"\">" +
            "                <input type=\"hidden\" name=\"miErrRecno\" value=\"0\">" +
            "                <input type=\"hidden\" name=\"mbIsError\" value=\"false\">" +
            "                <input type=\"hidden\" name=\"msNextOperation\" value=\"\">" +
            "                <input type=\"hidden\" name=\"mbNoRecordFound\" value=\"false\">" +
            "                <input type=\"hidden\" name=\"msExpressionMessage\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msIsGuidelinePresent\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msMultiLocation\" value=\"\">" +
            "                <input type=\"hidden\" name=\"msDefaultLocation\" value=\"\">" +
            "                <div class=\"form-group\">" +
            "                    <label for=\"userId\">User ID</label>" +
            "                    <input type=\"text\" class=\"form-control\" placeholder=\"User ID\" name=\"msUserId\" required>" +
            "                </div>" +
            "                <div class=\"form-group\">" +
            "                    <label for=\"password\">Password</label>" +
            "                    <input type=\"password\" class=\"form-control\" placeholder=\"Password\" name=\"msPassword\" required>" +
            "                </div>" +
            "                <button type=\"submit\" class=\"btn\" value=\"login\" name=\"save\" id=\"header-login-btn\">Submit</button>" +
            "                <a class=\"btn btn-default\" href=\"/patient-portal\">Forgot Password</button>" +
            "            </form>" +
            "        </div>" +
            "    </div>"
        });
    });

    // $('#request-a-consult-btn').on('click',function () {
    //     bootbox.dialog({
    //         size: 'large',
    //         message:
    //         '<div class="row">' +
    //         '<div class="col-md-6 col-md-push-3">' +
    //         '<p class="text-center h3">Request a Consult</p>' +
    //         '<a class="btn btn-lg btn-info center-block">Click Here To Start You Consult Now!</a>' +
    //         '<div id="call-us-area">' +
    //         '<p class="h3 text-center"><em>OR</em></p> ' +
    //         '<button class="btn btn-lg btn-warning center-block" id="call-us-button">Call Us</button>' +
    //         '</div>' +
    //         '</div>' +
    //         '</div>'
    //     });
    // });
    // $('body').on('click','#call-us-button',function(){
    //     $('#call-us-area').html(
    //         '<p id="orange-phone-number" class="text-center">844-4-VIP-DOC</p>' +
    //         '<p class="text-center" id="small-phone-number">(<a href="tel:844.484.7362">844.484.7362</a>)</p>'
    //     );
    // });
});