$(function() {
    $('#contact-form').validator();
    $('#recaptcha-error').hide();
    $('#contact-form').validator().on('submit', function (e) {
        //var response = grecaptcha.getResponse();
        //recaptcha failed validation
        // if(response.length == 0) {
        //     e.preventDefault();
        //     $('#recaptcha-error').show();
        // }

        if (!e.isDefaultPrevented()) {
            e.preventDefault();
            $('#contact-form-area').html('<div class="loader center-block"></div>');
            var url = $(this).attr('action');
            var formData = $(this).serializeArray();
            $.post(url,prepareRequestData(formData),function(response){
                $('#contact-form-area').html(
                    '<div class="alert alert-success" role="alert">'+ response.message +'</div>'
                );
            }).fail(function() {
                if(typeof response.message === 'undefined'){
                    response.message = 'An error has occurred';
                }
                $('#contact-form-area').html(
                    '<div class="alert alert-danger" role="alert">'+ response.message +'</div>'
                );
            });
        }
    })

    function prepareRequestData(formData){
        var requestData = {fields: {}};
        $.each(formData,function(i,field){
            if(field.name == 'g-recaptcha-response'){
                requestData.g_recaptcha_response = field.value;
            }else{
                requestData.fields[field.name] = field.value;
            }
        });
        return {data : JSON.stringify(requestData)};
    }
});