$(function() {
    $('#more-provider-details-btn').on('click', function() {
        bootbox.dialog({
            size: 'large',
            message:
            '<div class="row">' +
            '<div class="col-sm-6">' +
            '<p class="h3">Michael R. Bibighaus, M.D.</p>' +
            '<p class="h4">License No.</p>' +
            '<ul>' +
            '<li>New York: 231414 </li>' +
            '<li>Vermont: 042.0013247</li>' +
            '<li>Rhode Island: MD15445</li>' +
            '<li>Connecticut: 054900</li>' +
            '<li>Pennsylvania: MD436461</li>' +
            '<li>New Hampshire: 17680</li>' +
            '<li>Maryland: D82690</li>' +
            '<li>New Jersey: 25MA08355500</li>' +
            '<li>Massachusetts: 267726</li>' +
            '<li>Indiana: 01077826A</li>' +
            '<li>Florida: ME130899</li>' +
            '<li>California: 148540</li>' +
            '<li>Arizona: 54338</li>' +
            '<li>Washington: BB8683547</li>' +
            '<li>Missouri: 2009022380</li>' +
            '<li>Alabama: MD.28768</li>' +
            '</ul>' +
            '</div>' +
            '<div class="col-sm-6">' +
            '<p class="h3">Keith D. Algozzine, PA – C</p>' +
            '<p class="h4">License No.</p>' +
            '<ul>' +
            '<li>New York: 009365</li>' +
            '</ul>' +
            '</div></div>' +
            '<div class="row">' +
            '<div class="col-sm-6">' +
            '<p class="h3">Michael Ungerland, PA-C</p>' +
            '<p class="h4">License No.</p>' +
            '<ul>' +
            '<li>New York: 009969</li>' +
            '</ul>' +
            '</div>' +
            '<div class="col-sm-6">' +
            '<p class="h3">Danielle Wright, PA-C</p>' +
            '<p class="h4">License No.</p>' +
            '<ul>' +
            '<li>New York: 011897</li>' +
            '<li>California: 54399</li>' +
            '</ul>' +
            '</div></div>' +
            '<div class="row">' +
            '<div class="col-sm-6">' +
            '<p class="h3">Dr. Taylor Spencer, MD, MPH, FACEP</p>' +
            '<p class="h4">License No.</p>' +
            '<ul>' +
            '<li>New York: 259191</li>' +
            '</ul>' +
            '</div>' +
            '<div class="col-sm-6">' +
            '<p class="h3">Dr. Bret Wood, DO</p>' +
            '<p class="h4">License No.</p>' +
            '<ul>' +
            '<li>New York: 229341</li>' +
            '</ul>' +
            '</div></div>'
        });
    });
});